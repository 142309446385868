<template>
  <div
    class="search-result"
    :class="container === 'float' ? 'result-float' : 'result-default'"
  >
    <!-- loading -->
    <div
      v-show="isLoading"
      id="search-loading"
      class="flex items-center text-red-dark"
      :class="container !== 'float' ? 'p-4' : null"
    >
      <IconLoading class="mr-2 h-[24px] w-[24px]" />
      <span>Loading</span>
    </div>

    <div v-show="!isLoading">
      <!-- error -->
      <div v-show="isError" class="text-red-dark">
        Oops, something went wrong
      </div>
      <!-- no result -->
      <div v-show="!isError && !isAnyResult" class="p-4 text-red-dark">
        No Result Found
      </div>
      <!-- have result -->
      <div v-show="isAnyResult" class="">
        <div class="sections">
          <!-- tag section -->
          <div class="section">
            <div v-for="(suggestion, idx) in suggestions" :key="idx">
              <div v-if="suggestion.data.length > 0" class="section">
                <div class="section-heading capitalize">
                  <img
                    :src="getIconSrc(suggestion.name)"
                    alt="icon suggestion"
                    loading="lazy"
                    class="hh-icon"
                  />
                  <p>{{ t(suggestion.name) }}</p>
                </div>

                <div class="section-body">
                  <div v-for="(item, index) in suggestion.data" :key="item.id">
                    <template v-if="index <= 3">
                      <a
                        class="section-item block hover:bg-gray-100"
                        @click.prevent="goToSearchPage(suggestion.name, item)"
                      >
                        {{ item.attributes.name }}
                      </a>
                    </template>
                    <template v-else>
                      <button
                        v-if="index === 4 && !suggestion.isShowAll"
                        class="view-more-btn hover:bg-gray-100"
                        @click="emit('on-toggle-result', suggestion.name)"
                      >
                        View More
                      </button>
                      <a
                        class="section-item block hover:bg-gray-100"
                        :class="!suggestion.isShowAll ? 'hidden' : 'block'"
                        @click.prevent="goToSearchPage(suggestion.name, item)"
                      >
                        {{ item.attributes.name }}
                      </a>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- restaurant result -->
          <div v-show="restaurants.length" class="section restaurant-section">
            <div class="section-heading">
              <img
                src="~/assets/icons/icon-home-red.png"
                loading="lazy"
                alt="icon restaurant"
              />
              <p>Restaurant</p>
            </div>
            <div class="section-body">
              <div
                v-for="(restaurant, index) in restaurants"
                :key="restaurant.id"
                class="mb-2"
              >
                <template v-if="index <= 3">
                  <div>
                    <RestaurantCard
                      v-bind="cardMapper(restaurant)"
                      class="section-item"
                      @on-click="goToRestaurantPage(restaurant)"
                    />
                  </div>
                </template>
                <template v-else>
                  <button
                    v-if="index === 4 && !showAllRestaurants"
                    class="view-more-btn hover:bg-gray-100"
                    @click="emit('on-view-more-restaurants')"
                  >
                    View More
                  </button>
                  <div :class="!showAllRestaurants ? 'hidden' : 'block'">
                    <RestaurantCard
                      v-bind="cardMapper(restaurant)"
                      class="section-item"
                      @on-click="goToRestaurantPage(restaurant)"
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, defineAsyncComponent } from "vue";
import { useI18n } from "vue-i18n";
import { navigateTo } from "#imports";
import { getLanguage } from "~/composables/state/lang";
import IconLoading from "~icons/hh-icons/icon-loading";
import {
  type SuggestionResultSection,
  type RestaurantSearchSuggestion,
} from "~/api/common/searchSuggestion";
import { restaurantLink } from "~/services/common/searchSuggestion";
import { type RestaurantCardProps } from "~/types/Restaurant";
import { formatMoney } from "~/helpers/string";
import IconLocationRed from "~/assets/icons/icon-location-red.png";
import IconCourseRed from "~/assets/icons/icon-course-red.png";

defineOptions({
  name: "SuggestionView",
});

const RestaurantCard = defineAsyncComponent(
  () => import("~/components/restaurant/RestaurantCard/RestaurantCard.vue")
);

type RestaurantSuggestionTagData = {
  isShowAll: boolean;
  data: SuggestionResultSection[];
  name: string;
};

type RestaurantSuggestionTag = {
  cuisines: RestaurantSuggestionTagData;
  locations: RestaurantSuggestionTagData;
  mrtRoutes: RestaurantSuggestionTagData;
  btsRoutes: RestaurantSuggestionTagData;
  diningStyles: RestaurantSuggestionTagData;
  facilities: RestaurantSuggestionTagData;
  shoppingMalls: RestaurantSuggestionTagData;
};

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
  isError: {
    type: Boolean,
    required: true,
  },
  isAnyResult: {
    type: Boolean,
    required: true,
  },
  suggestions: {
    type: Object as PropType<RestaurantSuggestionTag>,
    required: true,
  },
  restaurants: {
    type: Array as PropType<RestaurantSearchSuggestion[]>,
    required: true,
  },
  showAllRestaurants: {
    type: Boolean,
    required: true,
  },
  isHybrid: {
    type: Boolean,
    default: false,
  },
  container: {
    type: String as PropType<"default" | "float">,
    default: "float",
  },
});

const emit = defineEmits<{
  (e: "on-view-more-restaurants"): void;
  (e: "on-toggle-result", data: string): void;
  (e: "on-tag-clicked", data: SuggestionResultSection): void;
  (e: "on-restaurant-clicked", data: RestaurantSearchSuggestion): void;
}>();

const { t } = useI18n({
  useScope: "global",
});

function getIconSrc(name: string) {
  return name === "location" ||
    name === "mrtRoute" ||
    name === "btsRoute" ||
    name === "shoppingMall"
    ? IconLocationRed
    : IconCourseRed;
}

function goToSearchPage(type: string, data: SuggestionResultSection) {
  if (props.isHybrid) {
    emit("on-tag-clicked", data);
    return;
  }

  let path = ``;
  if (type === "cuisine") {
    path = `/restaurants/search?cuisine_id[]=${data.id}&cuisine_name[]=${data.attributes.name}`;
  } else if (type === "location") {
    path = `/restaurants/search?location_id[]=${data.id}&location_name[]=${data.attributes.name}&location_total_restaurant[]=${data.attributes.totalRestaurants}`;
  } else if (type === "diningStyle") {
    path = `/restaurants/search?dining_style_id[]=${data.id}&dining_style_name[]=${data.attributes.name}`;
  } else if (type === "facility") {
    path = `/restaurants/search?tag_id[]=${data.id}&tag_name[]=${data.attributes.name}`;
  } else if (type === "mrtRoute") {
    path = `/restaurants/search?location_mrt_route_id[]=${data.id}&location_mrt_route_name[]=${data.attributes.name}&location_mrt_route_total_restaurant[]=${data.attributes.totalRestaurants}`;
  } else if (type === "shoppingMall") {
    path = `/restaurants/search?location_shopping_mall_id[]=${data.id}&location_shopping_mall_name[]=${data.attributes.name}&location_shopping_mall_total_restaurant[]=${data.attributes.totalRestaurants}`;
  } else if (type === "btsRoute") {
    path = `/restaurants/search?location_bts_route_id[]=${data.id}&location_bts_route_name[]=${data.attributes.name}&location_bts_route_total_restaurant[]=${data.attributes.totalRestaurants}`;
  } else {
    path = `/restaurants/search?${type}[]=${data.id}`;
  }

  return path;
}

function goToRestaurantPage(restaurant: RestaurantSearchSuggestion) {
  if (props.isHybrid) {
    emit("on-restaurant-clicked", restaurant);
    return;
  }

  const lang = getLanguage();
  navigateTo(`${lang}/restaurants/${restaurant.slug}`);
}

function cardMapper(
  restaurant: RestaurantSearchSuggestion
): RestaurantCardProps {
  return {
    id: restaurant.id,
    branchId: null,
    link: restaurantLink(restaurant.slug),
    image: {
      src: restaurant.coverImage ?? "",
      width: "76",
      height: "76",
      useMutator: true,
    },
    totalLocations: 1,
    primaryLocation: restaurant.location ?? "",
    name: restaurant.name,
    primaryCuisine: restaurant.cuisine ?? "",
    reviewsCount: restaurant.reviewsCount ?? 0,
    reviewsScore: restaurant.reviewsScore ?? 0,
    price: formatMoney({ val: restaurant.price ?? 0 }) ?? "",
    totalCovers: restaurant.totalCover ?? 0,
    isAds: false,
    cardToRender: "block-new",
    hideCuisine: true,
    hideLocation: true,
  };
}
</script>

<style scoped lang="scss">
.search-body {
  border: none;
  background: transparent;

  @apply absolute z-10 w-full bg-white px-4 py-2 text-left text-red-dark;

  border-radius: 10px;
  box-shadow: -1px 2px 25px rgba(0, 0, 0, 0.16);
}

.search-result {
  max-height: 100vh;
  overflow-y: scroll;

  .view-more-btn {
    @apply w-full py-2 text-left font-black  text-red-dark;
  }

  .sections {
    @apply mx-4;
  }

  .section-heading {
    @apply flex text-black;

    img {
      width: 18px;
      height: 18px;

      @apply mr-2;
    }
  }

  .section-body {
    @apply overflow-hidden py-2 text-sm text-gray-700;

    .restaurant-name {
      @apply truncate;
    }

    img {
      width: 55px;
      height: 55px;

      @apply mr-2;
    }

    .section-item {
      @apply w-full cursor-pointer py-1;
    }
  }

  .section.cuisine-section .section-body,
  .section.location-section .section-body {
    margin-left: 26px;
  }
}

.result-float {
  box-shadow: -1px 2px 25px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  @apply absolute  z-10 w-full border bg-white px-3 py-3 text-left;
}
</style>
